export const PRODUCT_REQUIRES_FORMULA = [
  {
    key: false,
    name: 'No'
  },
  {
    key: true,
    name: 'Si'
  }
]

export const FORMULA_VALUES = [
  {
    name: 'sphere',
    values: []
  },
  {
    name: 'cylinder',
    values: []
  },
  {
    name: 'axis',
    values: []
  },
  {
    name: 'addiction',
    values: []
  }
]

export const FORMULA_DETAILS = {
  sphere: {
    name: 'Esferas',
    singularName: 'Esfera',
    title: 'Ingresa las esferas disponibles',
    description: 'Puedes agregar un rango inferior y un rango superior, para agregar varias esferas disponible, o puedes agregar solo el rango inferior para una esfera particular.',
    getOptions: getSphereValues
  },
  cylinder: {
    name: 'Cilindros',
    singularName: 'Cilindro',
    title: 'Ingresa los cilindros disponibles',
    description: 'Puedes agregar un rango inferior y un rango superior, para agregar varios cilindros disponible, o puedes agregar solo el rango inferior para un cilindro particular.',
    getOptions: getCylinderValues
  },
  axis: {
    name: 'Ejes',
    singularName: 'Eje',
    title: 'Ingresa los ejes disponibles',
    description: 'Puedes agregar un rango inferior y un rango superior, para agregar varios ejes disponible, o puedes agregar solo el rango inferior para un eje particular.',
    getOptions: getAxisValues
  },
  addiction: {
    name: 'Adicciones',
    singularName: 'Adicción',
    title: 'Ingresa las adicciones disponibles',
    description: 'Puedes agregar un rango inferior y un rango superior, para agregar varias adicciones disponible, o puedes agregar solo el rango inferior para una adicción particular.',
    getOptions: getAddictionValues
  }
}

export const FORMULA_SPHERE_NAME = 'sphere'
export const FORMULA_CYLINDER_NAME = 'cylinder'
export const FORMULA_AXIS_NAME = 'axis'
export const FORMULA_ADDICTION_NAME = 'addiction'

const FORMULA_ITEM_NAME = {
  [FORMULA_SPHERE_NAME]: 'Esfera',
  [FORMULA_CYLINDER_NAME]: 'Cilindro',
  [FORMULA_AXIS_NAME]: 'Eje',
  [FORMULA_ADDICTION_NAME]: 'Adición'
}

const MIN_SPHERE = -30
const MAX_SPHERE = 25
const MIN_CYLINDER = -30
const MAX_CYLINDER = 30
const MIN_AXIS = 0
const MAX_AXIS = 180
const MIN_ADDICTION = 0
const MAX_ADDICTION = 5

export function getFormulaValue (value, decimals) {
  if (value === 0) {
    return parseFloat(value)
  }

  return parseFloat(value).toFixed(decimals)
}

function buildFormulaValues (min, max, interval, decimals, handlesPrefix) {
  if (isNaN(min) || isNaN(max)) {
    return []
  }

  const result = []

  let currentPosition = min
  while (currentPosition <= max) {
    const prefix = handlesPrefix && currentPosition > 0 ? '+' : ''
    const value = getFormulaValue(currentPosition, decimals)

    result.push({
      key: parseFloat(value),
      name: prefix + value
    })

    currentPosition += interval
  }

  return result
}

export function getSphereValues () {
  return buildFormulaValues(
    parseFloat(MIN_SPHERE),
    parseFloat(MAX_SPHERE),
    0.25,
    2,
    true
  )
}

export function getCylinderValues () {
  return buildFormulaValues(
    parseFloat(MIN_CYLINDER),
    parseFloat(MAX_CYLINDER),
    0.25,
    2,
    true
  )
}

export function getAxisValues () {
  return buildFormulaValues(
    parseFloat(MIN_AXIS),
    parseFloat(MAX_AXIS),
    10,
    0,
    false
  )
}

export function getAddictionValues () {
  return buildFormulaValues(
    parseFloat(MIN_ADDICTION),
    parseFloat(MAX_ADDICTION),
    0.25,
    2,
    true
  )
}

export function getFormulaItemName (name) {
  return FORMULA_ITEM_NAME[name] ? FORMULA_ITEM_NAME[name] : name
}
