<template>
  <v-card :class="['card-item-container', { 'app-border-bottom': printBorder }]" tile flat>
    <div class="image-container">
      <slot name="image"></slot>
    </div>
    <div class="item-container">
      <slot name="content"></slot>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    printBorder: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style scoped>
.card-item-container {
  display: flex;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}
.image-container {
  padding: 0.5rem 1rem 0.5rem 1.2rem;
}
.item-container {
  flex: 1;
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
/* 600px */
@media only screen and (max-width: 37.5rem) {
  .card-item-container {
    display: flex;
    cursor: pointer;
    padding-top: 0.25rem;
    padding-bottom: 0.75rem;
  }
  .image-container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
</style>
