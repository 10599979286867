<template>
  <card-container>
    <template v-slot:content class="card-content" v-if="orderDetail">
      <div class="order-title">
        Orden {{ orderDetail.order_number }}
      </div>
      <div>
        <div v-if="orderDetail.guide && orderDetail.guide.guide_number">
          <span class="guide-title">Guía:</span>&nbsp;
          <span class="guide-number">{{ orderDetail.guide.guide_number }}</span>
        </div>
        <div v-else>
          <span class="guide-title">Guía:</span>&nbsp;
          <a class="generate-guide-link" @click="generateOrderGuide(orderDetail.order_id)">Generar guía</a>
        </div>
      </div>
      <div class="creation-date">
        {{ dateFormat(orderDetail.creation_date, 'D MMM YYYY') }}
      </div>
      <div class="section-container app-border-top">
        <div class="section-title">
          Comprador
        </div>
        <div class="section-description first-capital-letter">
          {{ orderDetail.user.first_name }} {{ orderDetail.user.last_name }}
        </div>
        <div class="section-description">
          <span class="font-weight-bold">Teléfono:</span>
          <span>&nbsp;{{ orderDetail.address.cell_phone }}</span>
          <span v-if="orderDetail.address.landline_phone">,&nbsp;{{ orderDetail.address.landline_phone }}</span>
        </div>
      </div>
      <div class="section-container app-border-top">
        <div class="section-title">
          Dirección
        </div>
        <div class="section-description">
          {{ orderDetail.address.city.name }}, {{ orderDetail.address.region.name }}
        </div>
        <div class="section-description first-capital-letter">
          {{ orderDetail.address.address }} {{ orderDetail.address.additional_address }}, {{ orderDetail.address.neighborhood }}
        </div>
      </div>
      <div class="section-container app-border-top">
        <div class="section-title">
          Pago
        </div>
        <div class="section-description font-weight-bold">
          {{ getOrderTotalPrice }}
        </div>
        <div class="section-description" v-if="orderDetail.payment.date_approved">
          Aprobado el {{ dateFormat(orderDetail.payment.date_approved, 'D MMM YYYY') }}
        </div>
        <div :class="'section-description payment-' + orderDetail.payment.status">
          {{ getPaymentStatus(orderDetail.payment.status) }}
        </div>
      </div>
      <div class="section-container app-border-top">
        <div class="section-title">
          Entrega
        </div>
        <div class="section-description">
          {{ getDeliveryTypeDescription(orderDetail.delivery.delivery_type) }}
        </div>
        <div class="section-description">
          <div :class="'status-description-' + getDeliveryLastRecord.status" v-if="deliveryHasFinalStatus">
            {{ getDeliveryStatusTitle(getDeliveryLastRecord.status) }} el {{ dateFormat(getDeliveryLastRecord.creation_date, 'D MMM YYYY') }}
          </div>
          <div v-else>
            <span v-if="toBePickedUpInStore">
              Retira a partir del {{ dateFormat(addDaysToCurrentDate(orderDetail.delivery.delivery_days), 'D MMM YYYY') }}
            </span>
            <span v-else>
              Llega el {{ dateFormat(addDaysToCurrentDate(orderDetail.delivery.delivery_days), 'D MMM YYYY') }}
            </span>
          </div>
        </div>
        <div class="section-description" v-if="!!getCarrierInfo(orderDetail.delivery.carrier_id)">
          <span class="delivery-name">Envío realizado por&nbsp;{{ getCarrierInfo(orderDetail.delivery.carrier_id).name }}</span>
        </div>
        <div>
          <a class="delivery-detail-link" @click="deliveryDetail(orderDetail.order_id)">
            Detalle del envío
          </a>
        </div>
      </div>
      <div class="section-container app-border-top" v-if="orderDetail.file_urls">
        <div class="section-title">
          Formulas
        </div>
        <div class="section-description">
          <div class="text-left">
            <div v-for="(url, index) in orderDetail.file_urls" :key="'file_url_' + index">
              <a class="file-link" :href="url" target="_blank">Archivo {{index+1}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="section-container app-border-top">
        <div class="section-title">
          Productos
        </div>
        <div v-for="(product, index) in orderDetail.products" :key="product.product_id+'_'+index" @click="detailProduct(product.product_id)">
          <list-image-item-container :printBorder="false">
            <template v-slot:image>
              <div class="product-image-container">
                <v-img class="product-image" :src="getProductCoverImage(index)" aspect-ratio="1"></v-img>
              </div>
            </template>
            <template v-slot:content>
              <div class="list-item-title first-capital-letter">
                {{ product.name }}
                <span v-if="product.variant">&nbsp;({{ product.variant.name }})</span>
              </div>
              <span class="category-content">{{product.category.name}}</span>
              <div class="product-price">
                {{ getProductPrice(index) }}
              </div>
              <div class="text-left caption" v-if="product.formula">
                <span v-for="(formula, index) in product.formula" :key="'product_formula_' + index">
                  <span>{{ getFormulaItemName(formula.name) }}:</span>
                  <span class="grey--text text--darken-2">&nbsp;{{ formula.value }} </span>
                </span>
              </div>
              <div class="product-quantity">
                {{ getProductHumanizedQuantity(index) }}
              </div>
            </template>
          </list-image-item-container>
        </div>
      </div>
      <div class="cost-container app-border-top">
        <div class="section-title">
          Costo
        </div>
        <v-row class="section-description" no-gutters>
          <v-col>
            Total del producto
          </v-col>
          <v-col class="text-right">
            {{ getOrderProductTotalPrice }}
          </v-col>
        </v-row>
        <v-row class="section-description" no-gutters>
          <v-col>
            Envío
          </v-col>
          <v-col class="text-right">
            <span class="green--text text--darken-2" v-if="orderDetail.delivery.cost === 0">&nbsp;Gratis</span>
            <span v-else>{{ currency(orderDetail.delivery.cost) }}</span>
          </v-col>
        </v-row>
        <v-row class="section-description font-weight-bold" no-gutters>
          <v-col>
            Total
          </v-col>
          <v-col class="text-right">
            {{ getOrderTotalPrice }}
          </v-col>
        </v-row>
      </div>
    </template>
  </card-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import CardContainer from '@/components/Card/CardContainer'
import ListImageItemContainer from '@/components/List/ListImageItemContainer'
import { getDeliveryStatusTitle, DELIVERY_STATUS_DELIVERED, DELIVERY_STATUS_RETURNED, getCarrierInfo } from '@/shared/delivery'
import { getDeliveryTypeDescription, DELIVERY_TYPE_COLLECT_IN_STORE } from '@/shared/deliveryTypes'
import { dateFormat, currency } from '@/shared/format'
import { getPaymentStatus } from '@/shared/payment'
import { getFormulaItemName } from '@/shared/formula'
import { addDaysToCurrentDate } from '@/shared/date'

export default {
  mixins: [navigationMixin],
  components: {
    CardContainer,
    ListImageItemContainer
  },
  computed: {
    ...mapState('order', ['orderDetail']),
    ...mapGetters('order', ['getProductCoverImage', 'getProductPrice', 'getProductHumanizedQuantity', 'getOrderProductTotalPrice', 'getOrderTotalPrice', 'getDeliveryLastRecord']),
    deliveryHasFinalStatus () {
      const record = this.getDeliveryLastRecord

      return record && (record.status === DELIVERY_STATUS_DELIVERED || record.status === DELIVERY_STATUS_RETURNED)
    },
    toBePickedUpInStore () {
      return this.orderDetail.delivery.delivery_type === DELIVERY_TYPE_COLLECT_IN_STORE
    }
  },
  methods: {
    dateFormat,
    currency,
    getPaymentStatus,
    getFormulaItemName,
    getDeliveryStatusTitle,
    addDaysToCurrentDate,
    getDeliveryTypeDescription,
    getCarrierInfo,
    ...mapActions('order', ['getOrder', 'generateGuide', 'setOrderProperty']),
    ...mapActions('notification', ['showNotification']),
    async generateOrderGuide (orderID) {
      const order = await this.generateGuide(orderID)
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      this.setOrderProperty({ property: 'guide', value: order.guide })

      this.showNotification({ message: 'Guía generada exitosamente.', color: 'var(--app-popup-bg-color-success)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
    },
    detailProduct (id) {
      this.goToRouteByName('update-product', { id })
    },
    deliveryDetail (id) {
      this.goToRouteByName('delivery', { id })
    }
  },
  mounted () {
    this.getOrder(this.$route.params.id)
  },
  beforeRouteUpdate (to, from, next) {
    this.getOrder(to.params.id)
    next()
  }
}
</script>
<style scoped>
.card-content {
  height: 100%;
}
.order-title {
  font-size: 1.125rem;
  font-weight: bold;
}
.guide-title {
  font-size: 0.875rem;
  font-weight: bold;
}
.guide-number {
  font-size: 0.875rem;
}
.generate-guide-link {
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.813rem;
  font-weight: 600;
  margin-top: 0.5rem;
}
.creation-date {
  font-size: 0.875rem;
  padding-bottom: 0.75rem;
  color: #7D7D7DFF;
}
.section-container {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.cost-container {
  padding-top: 0.75rem;
}
.section-title {
  font-size: 1rem;
  font-weight: 600;
}
.section-description {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}
.status-description-delivered {
  color: var(--app-color-delivery-delivered);
  font-weight: 600;
}
.status-description-returned {
  color: var(--app-color-delivery-returned);
  font-weight: 600;
}
.delivery-name {
  font-weight: 600;
}
.delivery-detail-link {
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.813rem;
  font-weight: 600;
  margin-top: 0.5rem;
}
.product-image {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
}
.product-image-container {
  position: relative;
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
}
.list-item-title {
  padding-top: 0.125rem;
  font-size: 0.938rem;
  line-height: 1.125rem;
}
.category-content {
  color: #9E9E9E;
  font-size: 0.813rem;
  display: inline-block;
}
.category-separator {
  margin-left: 0.3rem;
  margin-right: 0.5rem;
}
.product-price {
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}
.product-quantity {
  text-align: left;
  font-size: 0.875rem;
  color: #7D7D7DFF;
}
.file-link {
  color: #1565c0 !important;
}
</style>
